export const SERVICES = [
  {
    code: 'iban4u',
    value: 'IBAN4U'
  },
  {
    code: 'debit_cards',
    value: 'Debit Cards'
  },
  {
    code: 'clopes',
    value: 'B2B2C Closed Loop Solution'
  },
];
