export const TURNOVER = [
  {
    code: '0_500',
    value: '0-500k'
  },
  {
    code: '501_1M',
    value: '501-1M'
  },
  {
    code: '1M_5M',
    value: '1M-5M'
  },
  {
    code: '5M_10M',
    value: '5M-10M'
  },
  {
    code: '10_25M',
    value: '10M-25M'
  },
  {
    code: '25M_up',
    value: '25M+'
  },
];
