export const LICENSE_REQUIRED = [
  {
    code: 'yes',
    value: 'Yes'
  },
  {
    code: 'no',
    value: 'No'
  },
  {
    code: 'in_progress',
    value: 'In Progress'
  },
  {
    code: 'not_required',
    value: 'Not required'
  }
];
